const baseUrl =
    'https://objectstorage.ap-chuncheon-1.oraclecloud.com/p/drqhKDR20zMwplqzEL6H4iAYCGnLQQEAadDGqZRt1Bwmw1Z-NdybacXh4geoWa8S/n/ax8vztmm9ho3/b/bucket-20240905-2203/o/preview/';
const images = {
    neocIMG : baseUrl + 'neoc.webp',
    flourishexelIMG : baseUrl + 'flourishexel.webp',
    betafixIMG : baseUrl + 'betafix.webp',
    melafixIMG : baseUrl + 'melafix.webp',
    pimafixIMG : baseUrl + 'pimafix.webp',
    bioExitGreenIMG : baseUrl + 'bio-exit-green.webp',
    neosolution1IMG : baseUrl + 'neosolution1.webp',
    neosolutionKIMG : baseUrl + 'neosolutionK.webp',
    neosolutionFeIMG : baseUrl + 'neosolutionFe.webp',
    guramiIMG : baseUrl + 'gurami.webp',
    inbokIMG : baseUrl + 'inbok.webp',
    StressShield : baseUrl + 'StressShield.webp',
    CyanoShield : baseUrl + 'CyanoShield.webp',
    NitrateRemover : baseUrl + 'NitrateRemover.webp',
    EasyBacteriaStep0 : baseUrl + 'EasyBacteriaStep0.webp',
    NeoBooster : baseUrl + 'NeoBooster.webp',
    NeoSolution2 : baseUrl + 'NeoSolution2.webp',
    filtermedium: baseUrl + 'filtermedium250ml.webp',
    turtlefix: baseUrl + 'turtlefix.webp',
    neofluid: baseUrl + 'neofluid300ml.webp',
    voogle: baseUrl + 'voogle250ml.webp',
};
export default images;
