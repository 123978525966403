import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider, IconButton, Autocomplete, TextField, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { ILiquidItemList, IFavorite, IFavoritesList, ILiquidItem } from './TabComponent';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import { Alert } from './Calculator';
import images from './images';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import axios from 'axios';

interface IProps {
    capacity: Number;
    liquidItemList: ILiquidItemList;
    favorites: IFavoritesList;
    setLiquidItemFavorite: (item: ILiquidItem) => void;
}


const Liquid = React.memo((props: IProps) => {
    const [items, setItems] = useState(props.liquidItemList);
    const [selectedItems, setSelectedItems] = useState(props.liquidItemList);
    const [bookmark, setBookmark] = useState<any>([]);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const listRef = useRef<HTMLInputElement | null>(null);
    const [proposalDialog, setProposalDialog] = useState<boolean>(false);
    const [proposalInput, setProposalInput] = useState<string>('');
    const [sendBtnLoading, setSendBtnLoading] = useState<boolean>(false);
    const [copySnackBar, setCopySnackBar] = useState<boolean>(false);
    function handleSelection(event: any, value: any) {
        if (value.length > 0) {
            setSelectedItems(value);
        } else {
            setSelectedItems(items);
        }
    }
    const handleClick = () => {
        // input 요소의 위치로 스크롤 이동
        // if (inputRef.current) inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });;
        if (inputRef.current) {
            setTimeout(() => {
                if (inputRef.current) inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }, 100); // 키보드 애니메이션 시간에 따라 조정
        }
    };

    const openProposalDialog = () => {
        setProposalDialog(true);
    };
    const closeProposalDialog = () => {
        setProposalDialog(false);
        setProposalInput('');
    };
    const onConfirmProposalDialog = async () => {
        setSendBtnLoading(true);
        const form_data = new FormData();
        form_data.append('message', proposalInput);
        await axios.post('https://script.google.com/macros/s/AKfycbzn74XlX0abXdafdlYIYcommAZUcLj3QwMqNKWmm2sw9bzB2OSjQcKfIeSPBJ9SUwvGZg/exec', form_data);
        setCopySnackBar(true);
        setSendBtnLoading(false);
        setProposalDialog(false);
        setProposalInput('');
    };


    useEffect(() => {
        setSelectedItems(props.liquidItemList);
    }, [props.liquidItemList]);
    return (


        <div>
            <Autocomplete
                ref={inputRef}
                multiple={true}
                fullWidth
                id="tags-outlined"
                limitTags={2}
                options={items}
                getOptionLabel={(option) => option.itemName}
                // defaultValue={[items[0]]}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="40"
                            src={option.image}
                            srcSet={option.image}
                            alt=""
                        />
                        {option.itemName}
                    </Box>
                )}

                onChange={handleSelection}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder="검색"
                        onClick={handleClick}
                    />
                )}
            />
            <div id={styles.list} style={{ height: '500px', minHeight: '350px', maxHeight: '500px', overflow: 'scroll', paddingBottom: '30px' }} className={styles['off-bottom']}>
                <div ref={listRef} className={styles.scrollbox}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem id={''} alignItems="flex-start" >
                            <ListItemAvatar>
                                <Avatar alt="" src={images.inbokIMG} sx={{ backgroundColor: '#BDBDBD', width: 50, height: 50 }} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={'안내'}
                                secondaryTypographyProps={{ component: 'div' }}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'block' }}
                                            component="div"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            추가되었으면 하는 제품을 제안해 주세요.
                                        </Typography>
                                        <Typography
                                            sx={{ display: 'block' }}
                                            component="div"
                                            variant="body2"
                                            color="text.primary"
                                        >

                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginTop: '10px' }}>
                                            <Button variant="contained" size='small' onClick={openProposalDialog} >요청</Button>
                                        </div>
                                    </React.Fragment>
                                }
                                sx={{ paddingLeft: '10px' }}
                            />

                        </ListItem>

                        <Divider variant="inset" component="li" />
                        {selectedItems.map(item => {
                            return (<>
                                <ListItem id={`${item.sq}-${item.itemName}`} alignItems="flex-start" >
                                    <ListItemAvatar>
                                        <Avatar alt={item.itemName} src={item.image} sx={{ backgroundColor: '#BDBDBD', width: 50, height: 50 }} //variant="rounded"
                                            imgProps={{
                                                sx: {
                                                    objectFit: 'contain',
                                                },
                                            }} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.itemName}
                                        secondaryTypographyProps={{ component: 'div' }}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'block' }}
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    권장 사용량 : {item.defaultDosage.recommendedDosage1} 당 {item.defaultDosage.recommendedDosage2}
                                                </Typography>
                                                <Typography
                                                    sx={{ display: 'block' }}
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    내 수조 사용량 : {props.capacity === 0 ? '?' : ((item.defaultDosage.perLiterFineCapacity) * Number(props.capacity)).toFixed(2)} {item.defaultDosage.perLiterFineCapacityUnit}
                                                </Typography>
                                                {!!item.itemDescription && <Typography
                                                    sx={{ display: 'block' }}
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    설명 : {item.itemDescription}
                                                </Typography>
                                                }
                                            </React.Fragment>
                                        }
                                        sx={{ paddingLeft: '10px' }}
                                    />
                                    <IconButton aria-label="favorites" size="large" onClick={(e) => {
                                        props.setLiquidItemFavorite(item);
                                    }}>
                                        {item.favorite ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                                    </IconButton>
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </>);
                        })}

                    </List>
                    <Snackbar open={copySnackBar} autoHideDuration={2000} onClose={() => setCopySnackBar(false)} sx={{ position: 'fixed', bottom: 20 }} >
                        <Alert onClose={() => setCopySnackBar(false)} severity="success" sx={{ width: '100%', color: 'white' }} style={{ backgroundColor: '#88BFCF', fontWeight: 'bold', color: 'white' }}>
                            소중한 의견이 전달되었습니다. 감사합니다.
                        </Alert>
                    </Snackbar>
                    {proposalDialog && <>
                        <Dialog open={true} onClose={closeProposalDialog}>
                            <DialogTitle>제안해주세요.</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    어떠한 제안이라도 좋습니다!👍<br />
                                    필요하신 약품 혹은 물 생활 용품에 대해 추가를 원하신다면, 검토 후 반영할 수 있도록 노력하겠습니다.<br />
                                    (아쿠아가든 제품은 당사의 요청에 따라 추가해 드릴 수 없습니다.)
                                </DialogContentText>
                                <TextField
                                    value={proposalInput}
                                    margin="dense"
                                    id="name"
                                    label="입력란"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setProposalInput(e.target.value)}
                                    disabled={sendBtnLoading}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeProposalDialog} disabled={sendBtnLoading}>취소</Button>
                                <LoadingButton
                                    onClick={onConfirmProposalDialog}
                                    endIcon={<SendIcon />}
                                    loading={sendBtnLoading}
                                    loadingPosition="end"
                                >
                                    <span>제출</span>
                                </LoadingButton>
                            </DialogActions>

                        </Dialog>
                    </>}
                </div>
                <div className={styles.shadows} aria-hidden="true"></div>
            </div >
        </div >
    );
});

export default Liquid;;