import {Box, Tabs, Tab, Typography} from '@mui/material';
import React, {useState, useCallback, useEffect} from 'react';
import Liquid from './Liquid';
import Flooring from './Flooring';
import Favorites from './Favorites';
import images from './images';
import productImages from './images/productIMG';
import {Favorite} from '@mui/icons-material';
import LiquidItem from './LiquidItems'
interface TabComponentProps {
    capacity: number;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IDosage {
    situation: string;
    recommendedDosage1: string;
    recommendedDosage2: string;
    perLiterFineCapacity: number;
    perLiterFineCapacityUnit: string;
}

export interface ILiquidItem {
    sq: number;
    type: string;
    typeName: string;
    favorite: boolean;
    itemName: string;
    itemDescription: string;
    itemSearchText: string[];
    itemSearchTag: string[];
    defaultDosage: IDosage;
    dosages: IDosage[];
    image: any;
    productImage: any;
}

export interface IFavorite {
    sq: number;
    type: string;
}
export interface IFavoritesList extends Array<IFavorite> {}

export interface ILiquidItemList extends Array<ILiquidItem> {}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{p: 2}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const TabComponent = (props: TabComponentProps) => {
    const itemLists: ILiquidItem[] = LiquidItem;

    const flooringItemLists: ILiquidItemList | (() => ILiquidItemList) = [];

    const [value, setValue] = useState(0);
    const [liquidItemList, setLiquidItemList] = useState<ILiquidItemList>(itemLists);
    const [flooringItemList, setFlooringItemList] = useState<ILiquidItemList>(flooringItemLists);
    const [favoriteLiquidItemList, setFavoriteLiquidItemList] = useState<ILiquidItemList>([]);
    const [favorites, setFavorites] = useState<IFavoritesList>([]);

    useEffect(() => {
        if (localStorage.getItem('liquidFavorites')) {
            const liquidFavorites: IFavoritesList = JSON.parse(localStorage.getItem('liquidFavorites') || '');

            const updatedLiquidItemList = liquidItemList.map((item) => {
                const matchingFavorite = liquidFavorites.find((favorite) => favorite.type === item.type && favorite.sq === item.sq);
                if (matchingFavorite) {
                    return {...item, favorite: true};
                }
                return item;
            });
            setFavorites(liquidFavorites);
            setLiquidItemList(updatedLiquidItemList);
            setFavoriteLiquidItemList(updatedLiquidItemList.filter((item) => item.favorite));
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const setLiquidItemFavorite = (item: ILiquidItem) => {
        const targetItemIndex = liquidItemList.findIndex((target) => target.sq === item.sq);
        let updatedTargetItemList = liquidItemList.map((selectedItem, index) => {
            if (index === targetItemIndex) {
                return {...selectedItem, favorite: !selectedItem.favorite};
            }
            return selectedItem;
        });
        // updatedTargetItemList = updatedTargetItemList.sort((a, b) => {
        //     if (a.favorite && !b.favorite) {
        //         return -1;
        //     } else if (!a.favorite && b.favorite) {
        //         return 1;
        //     }
        //     return a.sq - b.sq;
        // });

        setLiquidItemList(updatedTargetItemList);
        setFavoriteLiquidItemList(updatedTargetItemList.filter((newItem) => newItem.favorite));

        // const itemExists = favorites.some(prevItem => prevItem.type === item.type && prevItem.sq === item.sq);

        const itemIndex = favorites.findIndex((prevItem) => prevItem.type === item.type && prevItem.sq === item.sq);
        if (itemIndex !== -1) {
            // 이미 항목이 존재하므로 제거
            const newFavorites = [...favorites];
            newFavorites.splice(itemIndex, 1);
            localStorage.setItem('liquidFavorites', JSON.stringify(newFavorites));
            setFavorites(newFavorites);
        } else {
            // 항목이 존재하지 않으므로 추가
            const newFavorites = [...favorites, {type: item.type, sq: item.sq}];
            localStorage.setItem('liquidFavorites', JSON.stringify(newFavorites));
            setFavorites(newFavorites);
        }

        // if (!itemExists) {
        // newItem을 배열에 추가, 추후 추가적인 아이템들을 관리하기위해 favorite 배열 별도로 보관
        // }
    };

    return (
        <>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="약품(수질)" {...a11yProps(0)} style={{fontFamily: 'Y_Spotlight'}} />
                        <Tab label="바닥재" {...a11yProps(1)} style={{fontFamily: 'Y_Spotlight'}} disabled />
                        <Tab label="준비중" {...a11yProps(2)} style={{fontFamily: 'Y_Spotlight'}} disabled />
                        <Tab label="즐겨찾기" {...a11yProps(3)} style={{fontFamily: 'Y_Spotlight'}} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Liquid
                        capacity={props.capacity}
                        liquidItemList={liquidItemList}
                        favorites={favorites}
                        setLiquidItemFavorite={setLiquidItemFavorite}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Flooring
                        capacity={props.capacity}
                        liquidItemList={flooringItemList}
                        favorites={favorites}
                        setLiquidItemFavorite={setLiquidItemFavorite}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Favorites
                        capacity={props.capacity}
                        liquidItemList={favoriteLiquidItemList}
                        favorites={favorites}
                        setLiquidItemFavorite={setLiquidItemFavorite}
                    />
                </TabPanel>
            </Box>
        </>
    );
};

export default TabComponent;
