import images from './images';
import productImages from './images/productIMG';
const itemLists = [
    {
        sq: 0,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오C',
        itemDescription: '',
        itemSearchText: ['neoC'],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '40L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.25,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.neocIMG,
        productImage: productImages.neocIMG,
    },
    {
        sq: 1,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오A',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '40L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.25,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [
            {
                situation: '세팅초기, 수조이상시',
                recommendedDosage1: '40L',
                recommendedDosage2: '20cc',
                perLiterFineCapacity: 0.5,
                perLiterFineCapacityUnit: 'cc',
            },
        ],
        image: images.neocIMG,
        productImage: productImages.neoaIMG,
    },
    {
        sq: 2,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오V 블랙',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '40L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.25,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.neocIMG,
        productImage: productImages.neovIMG,
    },
    {
        sq: 3,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '플로엑셀',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '40L',
            recommendedDosage2: '5cc',
            perLiterFineCapacity: 0.125,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.flourishexelIMG,
        productImage: '',
    },
    {
        sq: 4,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '멜라픽스',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '38L',
            recommendedDosage2: '5cc',
            perLiterFineCapacity: 0.1315789474,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.melafixIMG,
        productImage: '',
    },
    {
        sq: 5,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '피마픽스',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '38L',
            recommendedDosage2: '5cc',
            perLiterFineCapacity: 0.1315789474,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.pimafixIMG,
        productImage: '',
    },
    {
        sq: 6,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '베타픽스',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '3.8L',
            recommendedDosage2: '2.5cc',
            perLiterFineCapacity: 0.6578947368,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.betafixIMG,
        productImage: '',
    },
    {
        sq: 7,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오솔루션1',
        itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '평상시',
            recommendedDosage1: '10L',
            recommendedDosage2: '1cc',
            perLiterFineCapacity: 0.1,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [
            {
                situation: '환수시',
                recommendedDosage1: '10L',
                recommendedDosage2: '2cc',
                perLiterFineCapacity: 0.2,
                perLiterFineCapacityUnit: 'cc',
            },
        ],
        image: images.neosolution1IMG,
        productImage: productImages.neosolution1IMG,
    },
    {
        sq: 8,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오솔루션2',
        itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '10L',
            recommendedDosage2: '1cc',
            perLiterFineCapacity: 0.1,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.NeoSolution2,
        productImage: productImages.neosolution1IMG,
    },
    {
        sq: 9,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오솔루션K',
        itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '평상시',
            recommendedDosage1: '10L',
            recommendedDosage2: '1cc',
            perLiterFineCapacity: 0.1,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [
            {
                situation: '환수시',
                recommendedDosage1: '10L',
                recommendedDosage2: '2cc',
                perLiterFineCapacity: 0.2,
                perLiterFineCapacityUnit: 'cc',
            },
        ],
        image: images.neosolutionKIMG,
        productImage: productImages.neosolution1IMG,
    },
    {
        sq: 10,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오솔루션Fe',
        itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '평상시',
            recommendedDosage1: '10L',
            recommendedDosage2: '1cc',
            perLiterFineCapacity: 0.1,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [
            {
                situation: '환수시',
                recommendedDosage1: '10L',
                recommendedDosage2: '2cc',
                perLiterFineCapacity: 0.2,
                perLiterFineCapacityUnit: 'cc',
            },
        ],
        image: images.neosolutionFeIMG,
        productImage: productImages.neosolution1IMG,
    },
    {
        sq: 11,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '바이오엑시트그린',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '40L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.25,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.bioExitGreenIMG,
        productImage: '',
    },
    {
        sq: 12,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네이처팜 스트레스 쉴드',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: ['염소제거+점막보호'],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '50L',
            recommendedDosage2: '15cc',
            perLiterFineCapacity: 0.3,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.StressShield,
        productImage: '',
    },
    {
        sq: 13,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네이처팜 나이트레이트 리무버',
        itemDescription: '',
        itemSearchText: ['질산염제거제'],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '100L',
            recommendedDosage2: '15cc',
            perLiterFineCapacity: 0.15,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.NitrateRemover,
        productImage: productImages.nitrateRemoverIMG,
    },
    {
        sq: 14,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네이처팜 이지 박테리아 스텝 0',
        itemDescription: '',
        itemSearchText: ['염소중화제'],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '100L',
            recommendedDosage2: '30cc',
            perLiterFineCapacity: 0.3,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.EasyBacteriaStep0,
        productImage: productImages.easyBacteriaStep0IMG,
    },
    {
        sq: 15,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네이처팜 시아노 쉴드',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: ['시아노제거제'],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '50L',
            recommendedDosage2: '15cc',
            perLiterFineCapacity: 0.3,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.CyanoShield,
        productImage: '',
    },
    {
        sq: 16,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오 부스터 플랜츠',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '40L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.25,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.NeoBooster,
        productImage: '',
    },
    {
        sq: 17,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '이지라이프 필터미디엄(Filter Medium)',
        itemDescription: '작은 문제에는 정량의 2배, 심각한 문제 및 생물 이동시 3배를 사용합니다. (설명서 확인)',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '평상시',
            recommendedDosage1: '30L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.3333,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [
            {
                situation: '초기세팅, 물갈이시',
                recommendedDosage1: '30L',
                recommendedDosage2: '10cc',
                perLiterFineCapacity: 0.3333,
                perLiterFineCapacityUnit: 'cc',
            },
            {
                situation: '작은 문제가 있을 경우',
                recommendedDosage1: '30L',
                recommendedDosage2: '20cc',
                perLiterFineCapacity: 0.6666,
                perLiterFineCapacityUnit: 'cc',
            },
            {
                situation: '심각한 문제나 생물 이동 시',
                recommendedDosage1: '30L',
                recommendedDosage2: '30cc',
                perLiterFineCapacity: 1,
                perLiterFineCapacityUnit: 'cc',
            },
        ],
        image: images.filtermedium,
        productImage: productImages.filtermediumIMG,
    },
    {
        sq: 18,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '이지라이프 부글(Voogle)',
        itemDescription: `질병회복시, 생물운송 후 격리 : \n✅ 5일동안 매일 40L 당 10ml
        질병예방시 : \n✅ 1주일에 한 번 100L 당 10ml
        생물운송시 : \n✅ 기본사용량의 2배 (20L당 10ml)
        새로운 생물 입수 시 : \n✅ 3일동안 매일 40L 당 10ml`,
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '질병회복시',
            recommendedDosage1: '40L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.25,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [
            {
                situation: '질병예방시',
                recommendedDosage1: '100L',
                recommendedDosage2: '10cc',
                perLiterFineCapacity: 0.1,
                perLiterFineCapacityUnit: 'cc',
            },
            {
                situation: '생물운송시',
                recommendedDosage1: '40L',
                recommendedDosage2: '20cc',
                perLiterFineCapacity: 0.5,
                perLiterFineCapacityUnit: 'cc',
            },
            {
                situation: '생물운송 후 격리',
                recommendedDosage1: '40L',
                recommendedDosage2: '10cc',
                perLiterFineCapacity: 0.25,
                perLiterFineCapacityUnit: 'cc',
            },
            {
                situation: '새로운 생물 입수',
                recommendedDosage1: '40L',
                recommendedDosage2: '10cc',
                perLiterFineCapacity: 0.25,
                perLiterFineCapacityUnit: 'cc',
            },
        ],
        image: images.voogle,
        productImage: productImages.voogleIMG,
    },
    {
        sq: 19,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: 'API터틀픽스',
        itemDescription: '',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '19L',
            recommendedDosage2: '2.5cc',
            perLiterFineCapacity: 0.1315,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.turtlefix,
        productImage: productImages.turtlefixIMG,
    },
    {
        sq: 20,
        type: 'liquid',
        typeName: '약품',
        favorite: false,
        itemName: '네오 플루이드 NO CO2',
        itemDescription: '40리터당 10cc를 3일마다 투여, 주의사항을 꼭 확인하세요',
        itemSearchText: [''],
        itemSearchTag: [],
        defaultDosage: {
            situation: '',
            recommendedDosage1: '40L',
            recommendedDosage2: '10cc',
            perLiterFineCapacity: 0.25,
            perLiterFineCapacityUnit: 'cc',
        },
        dosages: [],
        image: images.neofluid,
        productImage: productImages.neofluidIMG,
    },
];

export default itemLists;
