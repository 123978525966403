import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary} from '@mui/joy';
import {ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider, IconButton} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import {ILiquidItem} from './TabComponent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import axios from 'axios';
import ProductDialog from './Dialogs/ProductDialog';
import {styled} from '@mui/material/styles';

const calculateCapacity = (capacity: number, perLiterFineCapacity: number) => {
    return capacity === 0 ? '?' : Number((perLiterFineCapacity * Number(capacity)).toFixed(2));
};

const Root = styled('div')(({theme}) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(1),
    },
}));
interface LiquidListItemProps {
    item: ILiquidItem;
    capacity: number;
    setLiquidItemFavorite: (item: ILiquidItem) => void;
    selectedItem: ILiquidItem;
    setSelectedItem?: (item: ILiquidItem) => void;
}

const LiquidListItem: React.FC<LiquidListItemProps> = ({item, capacity, setLiquidItemFavorite, selectedItem, setSelectedItem}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment key={`${item.sq}-li`}>
            <ListItem id={`${item.sq}-${item.itemName}`} alignItems="flex-start" sx={{padding: '8px'}}>
                <ListItemAvatar>
                    <Avatar
                        alt={item.itemName}
                        src={item.image}
                        sx={{backgroundColor: '#BDBDBD', width: 50, height: 50, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'}} //variant="rounded"
                        imgProps={{
                            sx: {
                                objectFit: 'contain',
                            },
                        }}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={item.itemName}
                    primaryTypographyProps={{
                        sx: {fontWeight: 'bold', fontSize: '18px'},
                    }}
                    secondaryTypographyProps={{component: 'div'}}
                    secondary={
                        <React.Fragment>
                            <Typography variant="body2" color="text.secondary">
                                {item.itemSearchTag.map((tag) => `# ${tag}`)}
                            </Typography>

                            <Box sx={{marginTop: '10px'}}>
                                <Typography sx={{display: 'block'}} component="div" variant="body2" color="text.primary">
                                    <b>권장 사용량 : </b>
                                    {item.defaultDosage.recommendedDosage1} 당 {item.defaultDosage.recommendedDosage2}
                                </Typography>
                                <Typography sx={{display: 'block'}} component="div" variant="body2" color="text.primary">
                                    <b>내 수조 사용량 :</b>{' '}
                                    <Typography component="span" sx={{fontWeight: 'bold', color: '#88bfcf'}}>
                                        {calculateCapacity(Number(capacity), item.defaultDosage.perLiterFineCapacity)}{' '}
                                    </Typography>
                                    {item.defaultDosage.perLiterFineCapacityUnit}
                                </Typography>
                                {/* {!!item.itemDescription && (
                        <Typography sx={{display: 'block'}} component="div" variant="body2" color="text.primary">
                            <b>설명 :</b> {item.itemDescription}
                        </Typography>
                    )} */}
                                {/* variant="outlined" */}
                                {(item.dosages.length > 0 || !!item.itemDescription) && (
                                    <AccordionGroup color="neutral" variant="soft" sx={{marginTop: '5px'}}>
                                        <Accordion>
                                            <AccordionSummary>
                                                <div style={{display: 'flex'}}>
                                                    <SearchIcon />
                                                    <Typography sx={{display: 'block'}} component="div" variant="body2" color="text.primary">
                                                        상세정보
                                                    </Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {item.dosages.length > 0 && (
                                                    <Typography sx={{display: 'block'}} component="div" variant="body2" color="text.secondary">
                                                        {(item.defaultDosage.situation || '평상시') + ' : '}
                                                        <Typography component="span" sx={{fontWeight: 'bold', color: '#88bfcf'}}>
                                                            {calculateCapacity(Number(capacity), item.defaultDosage.perLiterFineCapacity)}{' '}
                                                        </Typography>
                                                        {item.defaultDosage.perLiterFineCapacityUnit}
                                                    </Typography>
                                                )}
                                                {item.dosages.map((dosage) => {
                                                    return (
                                                        <Typography sx={{display: 'block'}} component="div" variant="body2" color="text.secondary">
                                                            {dosage.situation + ' : '}
                                                            <Typography component="span" sx={{fontWeight: 'bold', color: '#88bfcf'}}>
                                                                {calculateCapacity(Number(capacity), dosage.perLiterFineCapacity)}
                                                            </Typography>
                                                            &nbsp;
                                                            {dosage.perLiterFineCapacityUnit}
                                                        </Typography>
                                                    );
                                                })}
                                                {!!item.itemDescription && (
                                                    <Root>
                                                        <Divider sx={{marginTop: '5px'}}>설명</Divider>
                                                        {item.itemDescription.split('\n').map((line, index) => (
                                                            <span key={index}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))}
                                                    </Root>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    </AccordionGroup>
                                )}
                            </Box>
                        </React.Fragment>
                    }
                    sx={{paddingLeft: '10px'}}
                />
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <IconButton
                        aria-label="favorites"
                        size="large"
                        sx={{padding: '12px 8px 12px 8px', marginRight: '-8px'}}
                        onClick={(e) => {
                            setLiquidItemFavorite(item);
                        }}>
                        {item.favorite ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                    </IconButton>
                    {/* <Divider variant="middle" /> */}
                    {item.productImage && setSelectedItem && setOpen && (
                        <IconButton
                            aria-label="favorites"
                            size="large"
                            sx={{padding: '12px 8px 12px 8px', marginRight: '-8px'}}
                            onClick={(e) => {
                                setOpen(true);
                                setSelectedItem(item);
                            }}>
                            <MoreHorizIcon />
                        </IconButton>
                    )}
                </div>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ProductDialog open={open} selectedItem={selectedItem} handleClose={handleClose} />
        </React.Fragment>
    );
};

export default LiquidListItem;
