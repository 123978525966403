import {List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider, IconButton, Autocomplete, TextField, Box} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import {ILiquidItemList, IFavorite, IFavoritesList, ILiquidItem} from './TabComponent';

import images from './images';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LiquidListItem from './LiquidListItem';

interface IProps {
    capacity: number;
    liquidItemList: ILiquidItemList;
    favorites?: IFavoritesList;
    setLiquidItemFavorite: (item: ILiquidItem) => void;
}

const Favorites = React.memo((props: IProps) => {
    const [items, setItems] = useState(props.liquidItemList);
    const [selectedItem, setSelectedItem] = useState<ILiquidItem>({
        sq: 0,
        type: '',
        typeName: '',
        favorite: false,
        itemName: '',
        itemDescription: '',
        itemSearchText: [],
        itemSearchTag: [],
        defaultDosage: {situation: '', recommendedDosage1: '', recommendedDosage2: '', perLiterFineCapacity: 0, perLiterFineCapacityUnit: 'cc'},
        dosages: [],
        image: '',
        productImage: '',
    });
    const [selectedItems, setSelectedItems] = useState<ILiquidItem[]>(props.liquidItemList);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const listRef = useRef<HTMLInputElement | null>(null);

    function handleSelection(event: any, value: any) {
        if (value.length > 0) {
            setSelectedItems(value);
        } else {
            setSelectedItems(items);
        }
    }
    const handleClick = () => {
        // input 요소의 위치로 스크롤 이동
        // if (inputRef.current) inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });;
        if (inputRef.current) {
            setTimeout(() => {
                if (inputRef.current) inputRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
            }, 100); // 키보드 애니메이션 시간에 따라 조정
        }
    };
    useEffect(() => {
        setSelectedItems(props.liquidItemList);
    }, [props.liquidItemList]);
    return (
        <div>
            <Autocomplete
                ref={inputRef}
                multiple
                fullWidth
                id="tags-outlined"
                limitTags={2}
                options={items}
                getOptionLabel={(option: ILiquidItem) => option.itemName}
                // defaultValue={[items[0]]}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img loading="lazy" width="40" src={option.image} srcSet={option.image} alt="" />
                        {option.itemName}
                    </Box>
                )}
                onChange={handleSelection}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} variant="standard" placeholder="검색" onClick={handleClick} />}
            />
            <div id={styles.list} style={{height: '500px', minHeight: '350px', overflow: 'scroll'}} className={styles['off-bottom']}>
                <div ref={listRef} className={styles.scrollbox}>
                    <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                        {selectedItems.map((item) => {
                            return (
                                <LiquidListItem
                                    key={item.sq}
                                    item={item}
                                    capacity={props.capacity}
                                    setLiquidItemFavorite={props.setLiquidItemFavorite}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                />
                            );
                        })}
                    </List>
                </div>
                <div className={styles.shadows} aria-hidden="true"></div>
            </div>
        </div>
    );
});

export default Favorites;
