import {
    Button,
    Card,
    CardContent,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Modal,
    ModalDialog,
    Stack,
    Typography,
    Input,
    Select,
    Option,
    Textarea,
    ModalClose,
} from '@mui/joy';
import React, {useState} from 'react';
import axios from 'axios';
import {useSnackbar} from '../../contexts/SnackbarContext';
import {FormHelperText} from '@mui/material';

const NewsUpdateCard = () => {
    const {showSnackbar} = useSnackbar();
    const initForm = {
        item: '',
        brandName: '',
        title: '',
        link: '',
        content: '',
    };
    const [formData, setFormData] = useState(initForm);
    const [open, setOpen] = useState<boolean>(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const handleOnClose = () => {
        setOpen(false);
        setFormData(initForm);
    };
    const onClickSubmitButton = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const form = (e.target as HTMLElement).closest('form');
        if (form && form.reportValidity()) {
            setBtnLoading(true);
            const form_data = new FormData();
            form_data.append('formGoogleSheetName', 'news_feed_request');
            form_data.append('item', formData.item);
            form_data.append('brandName', formData.brandName);
            form_data.append('title', formData.title);
            form_data.append('link', formData.link);
            form_data.append('content', formData.content);
            const {status} = await axios.post(
                'https://script.google.com/macros/s/AKfycbx87chfmQzBkDcj6WMgfPgNyIkQGOFs0Fuls3R4LnKZ9QyAA3u8qOdfe5bT3nc-gP6e_A/exec',
                form_data
            );
            if (status === 200) {
                handleOnClose();
                showSnackbar(
                    '요청하신 게시물은 검토 후 게시 여부가 결정될 예정입니다.\n검토 결과에 따라 반려될수 있습니다. 감사합니다.',
                    'success',
                    4000
                );
            } else {
                showSnackbar('오류가 발생했습니다. 계속 발생하는 경우 카카오톡 문의 부탁드립니다.', 'error', 4000);
            }
            setBtnLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <Card orientation="horizontal" size="sm" variant="plain" invertedColors>
                <div>
                    <Typography level="title-lg">소식을 추가해주세요</Typography>
                    <Typography level="body-sm">당신의 브랜드 혹은 내가 좋아하는 브랜드의 소식을 공유해주세요</Typography>
                </div>
                <CardContent orientation="horizontal">
                    <Button
                        variant="solid"
                        size="md"
                        color="primary"
                        aria-label="요청하기"
                        onClick={() => setOpen(true)}
                        sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600, width: 60}}>
                        <span>요청</span>
                    </Button>
                </CardContent>
            </Card>
            {open && (
                <Modal open={open} onClose={handleOnClose}>
                    <ModalDialog size="lg" sx={{width: '90%', maxWidth: '600px'}}>
                        <ModalClose />
                        <DialogTitle>물생활 소식을 공유해주세요</DialogTitle>

                        <span style={{fontSize: '11pt'}}>
                            귀사의 네이버 블로그 소식을 공유하고 싶다면,&nbsp;
                            <a href="https://open.kakao.com/o/sBpVJCTg">카카오톡 문의하기</a> 를 통해 연락부탁드립니다.
                        </span>

                        <form style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <Stack
                                spacing={2}
                                sx={{
                                    maxHeight: 'calc(100vh - 400px)',
                                    overflowY: 'scroll',
                                    flexGrow: 1,
                                }}>
                                <FormControl>
                                    <FormLabel>항목</FormLabel>
                                    <Select
                                        name="item"
                                        placeholder="항목선택"
                                        size="md"
                                        variant="outlined"
                                        value={formData.item}
                                        onChange={(e, newValue) => {
                                            setFormData({
                                                ...formData,
                                                item: newValue || '',
                                            });
                                        }}
                                        disabled={btnLoading}
                                        required>
                                        <Option value="입고">입고</Option>
                                        <Option value="재입고">재입고</Option>
                                        <Option value="출시">출시</Option>
                                        <Option value="이벤트">이벤트</Option>
                                        <Option value="안내/공지">안내/공지</Option>
                                        <Option value="세미나">세미나</Option>
                                        <Option value="전시회">전시회</Option>
                                        <Option value="기타">기타</Option>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>브랜드명</FormLabel>
                                    <Input
                                        name="brandName"
                                        value={formData.brandName}
                                        placeholder="예) 피쉬하이"
                                        onChange={handleInputChange}
                                        disabled={btnLoading}
                                        required
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>제목</FormLabel>
                                    <Input
                                        name="title"
                                        value={formData.title}
                                        placeholder="제목을 입력해주세요."
                                        onChange={handleInputChange}
                                        disabled={btnLoading}
                                        required
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>게시물 링크</FormLabel>
                                    <FormHelperText>링크는 해당 소식을 확인할 수 있는 '게시물'로 연결 되어야해요.</FormHelperText>
                                    <Input
                                        name="link"
                                        placeholder="https://"
                                        value={formData.link}
                                        onChange={handleInputChange}
                                        disabled={btnLoading}
                                        required
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>내용</FormLabel>
                                    <Textarea
                                        name="content"
                                        value={formData.content}
                                        placeholder="내용을 입력해주세요."
                                        onChange={handleInputChange}
                                        minRows={2}
                                        disabled={btnLoading}
                                        required
                                    />
                                </FormControl>
                            </Stack>
                            <Button onClick={onClickSubmitButton} loading={btnLoading} fullWidth sx={{mt: 2}}>
                                제출
                            </Button>
                        </form>
                    </ModalDialog>
                </Modal>
            )}
        </>
    );
};
export default NewsUpdateCard;
