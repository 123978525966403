import {useState, useRef, useEffect, useCallback} from 'react';
import {
    Tabs,
    Tab,
    Box,
    Button,
    TextField,
    InputAdornment,
    Switch,
    FormGroup,
    FormControlLabel,
    ThemeProvider,
    Autocomplete,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Divider,
    List,
    Avatar,
    Stack,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    InputBase,
    TextFieldProps,
    Snackbar,
} from '@mui/material/';
import {Canvas, useFrame} from '@react-three/fiber';
import {CalculateOutlined, HelpOutline} from '@mui/icons-material/';
import {FHTooltip} from '../components/ToolTip';

import Fade from '@mui/material/Fade';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import IosShareIcon from '@mui/icons-material/IosShare';
import RefreshIcon from '@mui/icons-material/Refresh';

import styles from './index.module.scss';
import {createTheme} from '@mui/material/styles';
import React from 'react';
import Liquid from './Liquid';
import {useLocation, useSearchParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import TabComponent from './TabComponent';
import {DoubleSide} from 'three';

import FishTank from './FishTank3D';
import {width} from '@mui/system';
import {useSnackbar} from '../../contexts/SnackbarContext';
import useShareOrCopyLink from '../../hooks/useShareOrCopyLink';

export interface IInputState {
    tankWidth: number;
    tankHeight: number;
    tankDepth: number;
    thickness: number;
    tankSand: number;
    waterLevel: number;
    tankWeight: number;
    sandAmountNeeded: number;
    capacity: number;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const theme = createTheme({
    palette: {
        neutral: {
            main: '#88bfcf',
            contrastText: '#fff',
        },
    },
});

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Calculator = React.memo(() => {
    const {shareOrCopyLink} = useShareOrCopyLink();

    const [searchParams, setSearchParams] = useSearchParams();
    const urlWidth = Number(searchParams.get('width')) || 0;
    const urlHeight = Number(searchParams.get('height')) || 0;
    const urlDepth = Number(searchParams.get('depth')) || 0;
    const urlThickNess = Number(searchParams.get('thickness')) || 0;
    const urlTankSand = Number(searchParams.get('tankSand')) || 0;
    const urlWaterLevel = Number(searchParams.get('waterLevel')) || 0;

    const TANK_STYLE_RATIO = 100;
    const [inputState, setInputState] = useState<IInputState>({
        tankWidth: urlWidth,
        tankHeight: urlHeight,
        tankDepth: urlDepth,
        thickness: urlThickNess,
        tankSand: urlTankSand,
        waterLevel: urlWaterLevel,
        tankWeight: 0,
        sandAmountNeeded: 0,
        capacity: 0,
    });
    const [calculatedState, setCalculatedState] = useState<IInputState | null>(null);

    const [value, setValue] = React.useState(0);
    const [detailSwitch, setDetailSwitch] = useState(urlThickNess + urlTankSand + urlWaterLevel > 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);

    // refs
    const faceFrontRef = useRef<HTMLDivElement>(null);
    const faceBackRef = useRef<HTMLDivElement>(null);
    const faceLeftRef = useRef<HTMLDivElement>(null);
    const faceRightRef = useRef<HTMLDivElement>(null);
    const faceTopRef = useRef<HTMLDivElement>(null);
    const faceBottomRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, inputRef.current.value.length);
        }
    };
    useEffect(() => {
        if (urlWidth * urlHeight * urlDepth > 0) {
            calc();
        }
    }, []);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;
        if (showCopyTooltip) {
            timeout = setTimeout(() => {
                setShowCopyTooltip(false);
            }, 2000);
        } else {
            clearTimeout(timeout);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [showCopyTooltip]);

    const setInput = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const {id, value} = e.target;
            if (Number(value) < 1000) {
                setInputState((prevState) => ({
                    ...prevState,
                    [id]: Number(value),
                }));
            }
        },
        [setInputState]
    );

    const handleSwitch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.checked) {
                setInputState({
                    ...inputState,
                    thickness: 0,
                    tankSand: 0,
                    waterLevel: 0,
                });
                setCalculatedState({
                    ...inputState,
                    thickness: 0,
                    tankSand: 0,
                    waterLevel: 0,
                });
            }
            setDetailSwitch(e.target.checked);
        },
        [detailSwitch]
    );

    const changeFrontRearStyle = useCallback(
        (values: {width: number; height: number; depth: number}) => {
            if (faceFrontRef.current && faceBackRef.current) {
                const {width, height, depth} = values;
                // 어항 앞면
                faceFrontRef.current.style.width = width + 'px';
                faceFrontRef.current.style.height = height + 'px';
                faceFrontRef.current.style.transform = 'translateZ(' + depth / 2 + 'px)';

                // 어항 뒷면
                faceBackRef.current.style.width = width + 'px';
                faceBackRef.current.style.height = height + 'px';
                faceBackRef.current.style.transform = 'rotate(-180deg) translateZ(' + -(depth / 2) + 'px)';
            }
        },
        [faceFrontRef, faceBackRef]
    );

    const changeSideStyle = useCallback(
        (values: {width: number; height: number; depth: number}) => {
            if (faceLeftRef.current && faceRightRef.current) {
                const {width, height, depth} = values;
                faceLeftRef.current.style.width = depth + 'px';
                faceLeftRef.current.style.height = height + 'px';
                faceLeftRef.current.style.transform = 'translateX(' + -(depth / 2) + 'px) rotateY(90deg)';

                faceRightRef.current.style.width = depth + 'px';
                faceRightRef.current.style.height = height + 'px';
                faceRightRef.current.style.transform = 'translateX(' + (width - depth + depth / 2) + 'px) rotateY(90deg)';
            }
        },
        [faceLeftRef, faceRightRef]
    );

    const changeTopBottomStyle = useCallback(
        (values: {width: number; height: number; depth: number}) => {
            if (faceTopRef.current && faceBottomRef.current) {
                const {width, height, depth} = values;
                faceTopRef.current.style.width = width + 'px';
                faceTopRef.current.style.height = depth + 'px';
                faceTopRef.current.style.transform = 'translateY(' + -(depth / 2) + 'px) rotateX(90deg)';

                faceBottomRef.current.style.width = width + 'px';
                faceBottomRef.current.style.height = depth + 'px';
                faceBottomRef.current.style.transform = 'translateY(' + (height - depth + depth / 2) + 'px) rotateX(90deg)';
            }
        },
        [faceTopRef, faceBottomRef]
    );

    const setSand = useCallback(
        (sandLevel: number) => {
            if (faceFrontRef.current && faceBackRef.current && faceLeftRef.current && faceRightRef.current) {
                const sand = `<div style='width:100%; 
              height:${sandLevel}px;
              position:absolute;
              bottom:0;
              background-color:rgba(150, 96, 29, 0.714);
              margin:0px'></div>`;

                const sandBack = `<div style='width:100%; 
            height:${sandLevel}px;
            position:absolute;top:0;
            background-color:rgba(150, 96, 29, 0.714);
            margin:0px'></div>`;

                faceFrontRef.current.innerHTML = sand;
                faceLeftRef.current.innerHTML = sand;
                faceRightRef.current.innerHTML = sand;
                faceBackRef.current.innerHTML = sandBack;
            }
        },
        [faceFrontRef, faceBackRef, faceLeftRef, faceRightRef]
    );

    const calc = useCallback(() => {
        const {tankWidth, tankHeight, tankDepth, thickness, tankSand, waterLevel} = inputState;
        const min = Math.min(Number(tankWidth), Number(tankHeight), Number(tankDepth));

        // ###########
        const _tankWidth = Number(tankWidth) || 0;
        const _tankHeight = Number(tankHeight) || 0;
        const _tankDepth = Number(tankDepth) || 0;
        const _thickness = Number(thickness) || 0;
        const _tankSand = Number(tankSand) || 0;
        const _waterLevel = Number(waterLevel) || 0;
        let sum = 0;

        if (thickness) {
            var x = ((_tankWidth * 10 * _tankHeight * 10 * _thickness * 2.5) / 1000000) * 2;
            var y = ((_tankHeight * 10 * (_tankDepth * 10 - _thickness * 2) * _thickness * 2.5) / 1000000) * 2;
            var z = ((_tankWidth * 10 - _thickness * 2) * (_tankDepth * 10 - _thickness * 2) * _thickness * 2.5) / 1000000;
            sum = x + y + z;
        }
        let calcValue =
            ((_tankWidth - _thickness / 5) * (_tankDepth - _thickness / 5) * (_tankHeight - _tankSand - _waterLevel - _thickness / 10)) / 1000;
        const sandAmountNeeded = ((_tankWidth - _thickness / 5) * (_tankDepth - _thickness / 5) * _tankSand) / 1000;
        const newState = {
            ...inputState,
            sandAmountNeeded: Number(sandAmountNeeded.toFixed(2)),
            tankWeight: Number(sum.toFixed(2)),
            capacity: Number(calcValue.toFixed(2)),
        };
        setInputState(newState);
        setCalculatedState(newState);
    }, [changeFrontRearStyle, changeSideStyle, changeTopBottomStyle, inputState, setSand]);

    const handleClickCalcButton = () => {
        calc();
        if (inputState.tankWidth > 0 && inputState.tankHeight > 0 && inputState.tankDepth) {
            const copyTooltipDisplayCnt = sessionStorage.getItem('copyTooltipDisplayCnt');
            let dpCnt = copyTooltipDisplayCnt ? parseInt(copyTooltipDisplayCnt) : 0;
            // sessionStorage로 2번까지는 알려주기
            if (dpCnt < 2) {
                setShowCopyTooltip(true);
                sessionStorage.setItem('copyTooltipDisplayCnt', (dpCnt + 1).toString());
            }
        }
    };

    const copyToClipBoard = () => {
        const {tankWidth, tankDepth, tankHeight, tankSand, waterLevel, thickness} = inputState;

        let url =
            Number(tankWidth) + Number(tankDepth) + Number(tankHeight) + Number(tankSand) + Number(waterLevel) + Number(thickness) === 0
                ? 'https://fishhi.kr/calc'
                : `https://fishhi.kr/calc?width=${tankWidth}&height=${tankHeight}&depth=${tankDepth}&tankSand=${tankSand}&thickness=${thickness}&waterLevel=${waterLevel}`;
        shareOrCopyLink(url);
    };

    const resetInputState = () => {
        const initInputState = {
            tankWidth: 0,
            tankHeight: 0,
            tankDepth: 0,
            thickness: 0,
            tankSand: 0,
            waterLevel: 0,
            tankWeight: 0,
            sandAmountNeeded: 0,
            capacity: 0,
        };
        setInputState(initInputState);
        setCalculatedState(null);
    };
    return (
        <>
            <Helmet>
                <title>피쉬하이</title>
            </Helmet>

            <div className={styles['calcBody']}>
                <h1 className={styles['title']}>fishhi.kr</h1>
                {/* <h3 className={styles["description"]}>본인 어항에 맞는 약품 용량을 계산해보세요!!</h3> */}
                <div className={styles['flex-box--main']}>
                    <div style={{width: '300px', height: '300px'}}>
                        <FishTank
                            inputWidth={calculatedState?.tankWidth || 30}
                            inputHeight={calculatedState?.tankHeight || 30}
                            inputDepth={calculatedState?.tankDepth || 30}
                            inputTankSand={calculatedState?.tankSand || 0}
                            inputWaterLevel={calculatedState?.waterLevel || 0}
                        />
                    </div>
                </div>
                <div className={styles['flex-box--refresh']}>
                    <IconButton
                        aria-label="refresh"
                        sx={{
                            backgroundColor: '#000',
                            opacity: 0.38,
                            width: '30px',
                            height: '30px',
                            '&:hover': {backgroundColor: '#000', opacity: 0.5},
                        }}
                        onClick={resetInputState}>
                        <RefreshIcon sx={{color: 'white'}} />
                    </IconButton>
                </div>
                <div className={styles['main-section__tank-size']}>
                    <TextField
                        id="tankWidth"
                        label="가로(cm)"
                        value={inputState.tankWidth === 0 ? '' : inputState.tankWidth}
                        onChange={(e) => setInput(e)}
                        type="number"
                        sx={{m: 1, width: '110px'}}
                        inputProps={{inputMode: 'decimal', pattern: '[0-9.]*', min: '0'}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment className="notranslate" position="end">
                                    cm
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                    <TextField
                        id="tankDepth"
                        label="세로(cm)"
                        value={inputState.tankDepth === 0 ? '' : inputState.tankDepth}
                        onChange={(e) => setInput(e)}
                        type="number"
                        sx={{m: 1, width: '110px'}}
                        inputProps={{inputMode: 'decimal', pattern: '[0-9.]*', min: '0'}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment className="notranslate" position="end">
                                    cm
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                    <TextField
                        id="tankHeight"
                        label="높이(cm)"
                        value={inputState.tankHeight === 0 ? '' : inputState.tankHeight}
                        onChange={(e) => setInput(e)}
                        type="number"
                        sx={{m: 1, width: '110px'}}
                        inputProps={{inputMode: 'decimal', pattern: '[0-9.]*', min: '0'}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment className="notranslate" position="end">
                                    cm
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                </div>
                {detailSwitch && (
                    <div className={styles['main-section__tank-size']}>
                        <TextField
                            id="thickness"
                            label="유리두께(T)"
                            value={inputState.thickness === 0 ? '' : inputState.thickness}
                            onChange={(e) => setInput(e)}
                            type="number"
                            sx={{m: 1, width: '110px'}}
                            inputProps={{inputMode: 'decimal', pattern: '[0-9.]*', min: '0'}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="notranslate" position="end">
                                        T
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                        />
                        <TextField
                            id="tankSand"
                            label="바닥재(cm)"
                            value={inputState.tankSand === 0 ? '' : inputState.tankSand}
                            onChange={(e) => setInput(e)}
                            type="number"
                            sx={{m: 1, width: '110px'}}
                            inputProps={{inputMode: 'decimal', pattern: '[0-9.]*', min: '0'}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="notranslate" position="end">
                                        cm
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                        />
                        <TextField
                            id="waterLevel"
                            label="만수위까지(cm)"
                            value={inputState.waterLevel === 0 ? '' : inputState.waterLevel}
                            onChange={(e) => setInput(e)}
                            type="number"
                            sx={{m: 1, width: '110px'}}
                            inputProps={{inputMode: 'decimal', pattern: '[0-9.]*', min: '0'}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="notranslate" position="end">
                                        cm
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                        />
                    </div>
                )}
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch id="detailSwitch" checked={detailSwitch} onChange={(e) => handleSwitch(e)} />}
                            label="상세"
                            style={{marginRight: '10px'}}
                        />
                    </FormGroup>
                </div>

                <ThemeProvider theme={theme}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '10px'}}>
                        <Button
                            sx={{width: '100%', marginRight: '10px'}}
                            color="neutral"
                            variant="contained"
                            endIcon={<CalculateOutlined />}
                            fullWidth={true}
                            onClick={handleClickCalcButton}>
                            <b>계산하기</b>
                        </Button>
                        <FHTooltip
                            open={showCopyTooltip}
                            TransitionComponent={Fade}
                            TransitionProps={{timeout: 600}}
                            title="설정값 그대로 공유해보세요."
                            onOpen={() => {
                                setShowCopyTooltip(false);
                            }}
                            arrow
                            placement="top-end"
                            style={{fontSize: '24px'}}>
                            <Button onClick={copyToClipBoard} color="neutral" variant="contained">
                                <IosShareIcon />
                            </Button>
                        </FHTooltip>
                    </div>
                </ThemeProvider>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0'}}>
                    <div style={{display: 'flex', alignItems: 'center', height: '40px'}}>
                        <div>
                            🌊 수조의 물 용량은 약 <b>{`${inputState.capacity.toFixed(2)}L`}</b>
                            <FHTooltip title="유리두께를 입력하면 두께 값이 제외되어 계산됩니다." enterTouchDelay={0} arrow>
                                <IconButton style={{padding: '0px'}}>
                                    <HelpOutline fontSize={'small'} />
                                </IconButton>
                            </FHTooltip>
                        </div>
                    </div>
                    {detailSwitch && (
                        <>
                            <div style={{display: 'flex', alignItems: 'center', height: '40px'}}>
                                <div>
                                    ⚖️ 수조의 무게는 약 <b>{`${inputState.tankWeight.toFixed(2)}Kg`}</b>
                                    <FHTooltip
                                        title={
                                            <div>
                                                물의 무게를 제외한 수조의 대략적인 무게입니다.
                                                <br />
                                                유리두께 값이 필요합니다.
                                            </div>
                                        }
                                        enterTouchDelay={0}
                                        arrow>
                                        <IconButton style={{padding: '0px'}}>
                                            <HelpOutline fontSize={'small'} />
                                        </IconButton>
                                    </FHTooltip>
                                </div>
                            </div>
                            {Number(inputState.tankSand) > 0 && (
                                <div style={{display: 'flex', alignItems: 'center', height: '40px'}}>
                                    <div>
                                        바닥재는 약 <b>{`${inputState.sandAmountNeeded.toFixed(2)}L`}</b> 가 필요해요
                                        {/* <FHTooltip title="물의 무게를 제외한 수조의 대략적인 무게입니다." enterTouchDelay={0} arrow >
                                        <IconButton style={{ padding: '0px' }}>
                                            <HelpOutline fontSize={'small'} />
                                        </IconButton>
                                    </FHTooltip> */}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
                
                <TabComponent capacity={inputState.capacity} />
                <div style={{ fontSize: '11px', width:'100%', textAlign:'center', color:'gray' }}>본 서비스에서 제공되는 용량 계산은 참고용으로 제공되며,<br/>실제 결과와 차이가 있을 수 있습니다.<br/>본 용량 계산 결과는 정보 제공 목적일 뿐이며,<br/>당사는 이에 따른 직간접적인 손해 등 어떠한 형태의 손해에 대해서도<br/>법적 책임을 지지 않습니다.</div>
                <div style={{ fontSize: '7px', width:'100%', textAlign:'center' }}>This work is based on <a href="https://skfb.ly/6SuoD" target="_blank">"Betta Splendens"</a> by <a href="https://sketchfab.com/VapTor" target="_blank">BlueMesh</a> licensed under <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank">CC-BY-4.0</a>.</div>
            </div>
        </>
    );
});
export default Calculator;
